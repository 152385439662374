// Post-Details.js

// "saisons" ou "saison"
export const IsMoreThanOneSeason = nombreDeSaisons => {
  let season = 'saison'
  nombreDeSaisons > 1 && (season = 'saisons')
  return season
}

// Série en cours ou termine
export const EnCours = EnCours => {
  let serieTooltip = 'Série terminée'
  EnCours && (serieTooltip = 'Série en cours')
  return serieTooltip
}
