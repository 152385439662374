import React from 'react'
import styled from '@emotion/styled'

const TextBemol = styled.div`
  background-color: ${props => props.theme.colors.background};
  padding: 30px 15px 1px 15px;
  border-radius: 5px;
  margin-bottom: 100px;
  font-size: 0.85rem;
  color: ${props => props.theme.colors.text};
  h2 {
    margin-bottom: 0;
    padding: 3px;
    border-radius: 3px 3px 0 0;
    color: ${props => props.theme.colors.secondary};
    background: ${props => props.theme.colors.highlight};
  }
  p {
    padding: 10px 6px;
    border-radius: 0 0 3px 3px;
    line-height: 1.2rem;
    em {
      cursor: pointer;
      text-decoration: underline;
      font-style: normal;
      &:hover + del {
        color: ${props => props.theme.colors.text};
      }
    }
    del {
      color: ${props => props.theme.colors.background};
      text-decoration: none;
    }
  }
`
const PostBemol = props => {
  return (
    <>
      <TextBemol
        dangerouslySetInnerHTML={{
          __html: props.bemol.childMarkdownRemark.html,
        }}
      />
    </>
  )
}
export default PostBemol
