// PostStickyInfos.js
// PostVoirLeFilm.js

const NomDeLaCategorie = props => {
  let nomDeLaCategorie = ''

  switch (props.categories.nom) {
    case 'Fiction':
      nomDeLaCategorie = 'le film'
      break
    case 'Série':
      nomDeLaCategorie = 'la série'
      break
    case 'Documentaire':
      nomDeLaCategorie = 'le film'
      break
    case 'Autre':
      nomDeLaCategorie = ''
      break
    default:
      nomDeLaCategorie = ''
  }
  return nomDeLaCategorie
}

export default NomDeLaCategorie
