// Post-Details.js

const MinutesToHours = duree => {
  let hours = Math.floor(duree / 60)
  let minutes = duree % 60
  hours = hours === 0 ? '' : hours + 'h'

  // Ce format : 1h / 1h02 / 1h10
  if (minutes < 10) {
    if (minutes === 0) {
      minutes = ''
    } else {
      minutes = '0' + minutes
    }
  } else if (!hours) {
    minutes = minutes + 'min'
  } else {
    minutes = minutes + ''
  }

  return hours + minutes
}

export default MinutesToHours
