import React from 'react'
import styled from '@emotion/styled'
import ProgressBar from '../ProgressBar'

const BarProgressIndicateurs = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.tertiary};

  font-size: 0.9rem;
  color: ${props => props.theme.colors.text};

  /* Medium and large screens : */
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex-direction: row;
    padding: 0;
  }
`
const RowOne = styled.div`
  width: 100%;
  padding: 0 15px;

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: 50%;
    padding: 30px 15px;
    > div {
      margin-top: 7px;
    }
  }
`

const DescriptionIndicateurs = styled.div`
  background-color: ${props => props.theme.colors.tertiary};
  padding: 30px 15px 1px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 0.95rem;
  color: ${props => props.theme.colors.text};

  h2 {
    margin-bottom: 0;
    padding: 3px;
    border-radius: 3px 3px 0 0;
  }
  p {
    padding: 10px 6px;
    border-radius: 0 0 3px 3px;
    line-height: 1.4rem;
    em {
      cursor: pointer;
      text-decoration: underline;
      font-style: normal;
      &:hover + del {
        color: ${props => props.theme.colors.text};
      }
    }
    del {
      color: ${props => props.theme.colors.tertiary};
      text-decoration: none;
    }
  }

  h2:nth-of-type(1) {
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.representation};
  }
  p:nth-of-type(1) {
    border: 1px solid ${props => props.theme.colors.representation};
  }
  h2:nth-of-type(2) {
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.personnes_concernees};
  }
  p:nth-of-type(2) {
    border: 1px solid ${props => props.theme.colors.personnes_concernees};
  }
  h2:nth-of-type(3) {
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.feel_good};
  }
  p:nth-of-type(3) {
    border: 1px solid ${props => props.theme.colors.feel_good};
  }
  h2:nth-of-type(4) {
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.militant};
  }
  p:nth-of-type(4) {
    border: 1px solid ${props => props.theme.colors.militant};
  }
  h2:nth-of-type(5) {
    color: ${props => props.theme.colors.background};
    background-color: ${props => props.theme.colors.deconstruction};
  }
  p:nth-of-type(5) {
    border: 1px solid ${props => props.theme.colors.deconstruction};
  }
`

const PostIndicateurs = props => {
  const disabledOne = props.representation === 999999
  const disabledTwo = props.personnesConcernees === 999999
  const disabledThree = props.feelGood === 999999
  const disabledFour = props.militant === 999999
  const disabledFive = props.deconstruction === 999999

  return (
    <>
      <BarProgressIndicateurs>
        <RowOne>
          <div>
            Représentation et visibilisation
            <ProgressBar
              value={`${props.representation}0`}
              className={`${disabledOne ? 'disabled' : ''} representation`}
            />
          </div>
          <div>
            Personnes Concernées
            <ProgressBar
              value={`${props.personnesConcernees}0`}
              className={`${
                disabledTwo ? 'disabled' : ''
              } personnes-concernees`}
            />
          </div>
          <div>
            Feel Good
            <ProgressBar
              value={`${props.feelGood}0`}
              className={`${disabledThree ? 'disabled' : ''} feelgood`}
            />
          </div>
        </RowOne>
        <RowOne>
          <div>
            Militant
            <ProgressBar
              value={`${props.militant}0`}
              className={`${disabledFour ? 'disabled' : ''} militant`}
            />
          </div>
          <div>
            Déconstruction
            <ProgressBar
              value={`${props.deconstruction}0`}
              className={`${disabledFive ? 'disabled' : ''} deconstruction`}
            />
          </div>
        </RowOne>
      </BarProgressIndicateurs>

      <DescriptionIndicateurs
        dangerouslySetInnerHTML={{
          __html: props.descriptionIndicateurs.childMarkdownRemark.html,
        }}
      />
    </>
  )
}
export default PostIndicateurs
