import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

const TriggersWarning = styled.div`
  display: block;
  margin-bottom: 30px;
  color: ${props => props.theme.colors.militant};
  font-size: 0.9rem;
  .triggers-title {
    font-weight: bold;
  }
  em {
    line-height: 1.2;
  }
`
const ActiveDétailsTriggers = styled.span`
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #d37474;
  }
  & + div {
    transition: opacity 300ms ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
  &#isActive ~ #details {
    display: block;
    opacity: 1;
    position: absolute;
    overflow: scroll;
    margin: auto;
    margin-top: 3px;
    right: 0;
    left: 0;
    border-radius: 5px;
    background-color: #eeeeee;
    height: auto;
    max-height: 400px;
    width: 90vw;
    z-index: 10;
    border: 1px solid #aab0b6;
    box-shadow: 0 0 20px 0 #aab0b67a;
    color: #262626;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      width: 300px;
    }
    p {
      margin: 0 5px 10px 10px;
      &::after {
        content: '';
        margin: 10px auto 20px auto;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #aab0b6;
      }
    }
    h3 {
      font-size: 1rem;
      margin: 10px 5px 0 5px;
    }
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      right: 20%;
      left: 20%;
    }
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      right: 50%;
      left: 50%;
    }
  }
`

const PostTriggersWarning = props => {
  const [addClass, setAddClass] = useState(false)

  const idName = addClass ? 'isActive' : ''
  const voirOuCacher = addClass ? 'Fermer' : 'Voir'

  // Close Triggers Details (on click anyywhere except Details)
  useEffect(() => {
    const paragraphes = Array.from(document.querySelectorAll('#details p'))
    const titles = Array.from(document.querySelectorAll('#details h3'))

    document.addEventListener('click', function(e) {
      const isClickOnPara = paragraphes.some(trigger => e.target === trigger)
      const isClickOnTitles = titles.some(trigger => e.target === trigger)

      if (addClass) {
        if (!e.target.matches('.see-trigger')) {
          if (!e.target.matches('#details')) {
            if (!isClickOnPara) {
              if (!isClickOnTitles) {
                setAddClass(false)
              }
            }
          }
        }
      }
    })
  })

  return (
    <TriggersWarning>
      <em>
        <span className="triggers-title">Triggers Warning :&nbsp;</span>
        {props.triggersWarning.map(trigger => trigger.trigger).join(', ')}
        .&nbsp;
      </em>
      {props.descriptionTriggerWarning && (
        <ActiveDétailsTriggers
          id={idName}
          className="see-trigger"
          onClick={() => setAddClass(!addClass)}
        >
          [{voirOuCacher} les détails]
        </ActiveDétailsTriggers>
      )}

      {props.descriptionTriggerWarning && (
        <div
          id="details"
          dangerouslySetInnerHTML={{
            __html: props.descriptionTriggerWarning.childMarkdownRemark.html,
          }}
        />
      )}
    </TriggersWarning>
  )
}
export default PostTriggersWarning
