import React from 'react'
import styled from '@emotion/styled'
import 'cooltipz-css'
import { Link } from 'gatsby'
import { FaHourglassHalf, FaFilm } from 'react-icons/fa'
import { RiNumbersFill } from 'react-icons/ri'
import MinutesToHours from '../../hooks/use-minutes-to-hours'
import { IsMoreThanOneSeason, EnCours } from '../../hooks/use-handle-serie'

const Wrapper = styled.div`
  /* Custom Toottip */
  --cooltipz-bg-color: ${props => props.theme.colors.text};
  --cooltipz-text-color: ${props => props.theme.colors.background};
  --cooltipz-cursor: default;
  /* hide arrow : */
  [aria-label][data-cooltipz-dir][data-cooltipz-dir='bottom']:before {
    border-bottom-color: transparent;
  }
  /* margin on smallscreens : */
  [aria-label][data-cooltipz-dir][data-cooltipz-dir='bottom']:after {
    margin-left: 3rem;
  }

  margin: 0 auto 0.7rem;
  line-height: 1.7;
  max-width: ${props => props.theme.sizes.maxWidthCentered};

  .ico {
    margin: 0 0.2rem 0 0;
  }
  .point {
    display: none;
  }
  svg {
    vertical-align: middle;
  }

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    [aria-label][data-cooltipz-dir][data-cooltipz-dir='bottom']:after {
      margin-left: 0;
    }
    line-height: 1.4;
    .break {
      display: none;
    }
    .ico {
      margin: 0 0.2rem 0 0.4rem;
    }
    .point {
      display: inline-block;
      margin: 0 0.2rem 0 0.5rem;
    }
  }
`

const Coeur = styled.p`
  display: inline-block;
  text-shadow: 2px 0 2px #000000b3;
  margin-top: 0.5rem;
  transform: scale(1.3);
  z-index: 3;
  opacity: 0.9;
  transition: transform 200ms;
  &:active {
    transform: scale(3);
  }
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    margin-left: 10px;
  }
`

const Categorie = styled.p`
  display: inline-block;
  a {
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
`
const Realisateurice = styled.p`
  display: inline-block;
  span {
    margin: 0 0.2rem 0 0;
  }

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    span {
      margin: 0 0.5rem;
    }
  }
`

const Saisons = styled.p`
  display: inline-block;
`
const Duree = styled.p`
  display: inline-block;
`

const PostDetails = ({
  nombreDeSaisons,
  enCoursOuTerminee,
  duree,
  ...props
}) => {
  let isSerie = false
  let swithCreeReal = 'Réalisé par'
  let dureeEpisode = 'Durée'
  if (props.categories.nom === 'Série') {
    isSerie = true
    swithCreeReal = 'Créée par'
    dureeEpisode = "Durée d'un épisode"
  }

  return (
    <Wrapper>
      <Categorie>
        <FaFilm className="ico" />
        &nbsp;
        <Link to={`${props.basePath}/categorie/${props.categories.slug}/`}>
          {props.categories.nom}
        </Link>
      </Categorie>
      <br className="break" />
      <span className="point">•</span>
      <Realisateurice>
        <span className="ico">🎬</span>&nbsp;
        {`${swithCreeReal} ${props.realisateurices
          .map(realisateurice => realisateurice.nom)
          .join(', ')}`}
        &nbsp; ({props.dateDeSortie})
      </Realisateurice>

      {isSerie && (
        <>
          <br className="break" />
          <span className="point">•</span>
          <Saisons
            aria-label={EnCours(enCoursOuTerminee)}
            data-cooltipz-dir="bottom"
          >
            <RiNumbersFill className="ico" />
            {nombreDeSaisons}&nbsp;{IsMoreThanOneSeason(nombreDeSaisons)}
          </Saisons>
        </>
      )}

      <br className="break" />
      <span className="point">•</span>
      <Duree aria-label={dureeEpisode} data-cooltipz-dir="bottom">
        <FaHourglassHalf className="ico" />
        &nbsp;
        {MinutesToHours(duree)}
      </Duree>

      {props.coupsDeCoeur && (
        <>
          <br className="break" />
          <span aria-label="Coups de coeur !" data-cooltipz-dir="bottom">
            <Coeur>❤️</Coeur>
          </span>
        </>
      )}
    </Wrapper>
  )
}

export default PostDetails
