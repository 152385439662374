import React from 'react'
import styled from '@emotion/styled'
import NomDeLaCategorie from '../../hooks/use-nom-categorie'

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto 0.7rem;
  max-width: ${props => props.theme.sizes.maxWidthCentered};
  span {
    margin: 0 0.5rem;
  }
`
const Button = styled.button`
  cursor: pointer;
  float: right;
  color: #2e629e;
  margin-right: 30px;
  a {
    text-decoration: none;
    margin: 0 20px;
    transition: all 10ms;
    &:hover {
      color: white;
    }
  }
  &:hover {
    background-color: #2e629e;
  }
  &:hover a {
    color: white;
  }
`

const PostVoirLeFilm = props => {
  return (
    <Wrapper>
      <Button
        className="my-button btn-film-fix"
        aria-label={`Voir ${NomDeLaCategorie(props)}`}
      >
        <a href={props.voirLeFilm} target="_blank" rel="noreferrer">
          Voir {NomDeLaCategorie(props)}
        </a>
      </Button>
    </Wrapper>
  )
}
export default PostVoirLeFilm
