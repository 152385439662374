import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import ContainerBody from '../components/ContainerBody'
import PageBody from '../components/PageBody'
import TagList from '../components/TagList'
import PostIndicateurs from '../components/Post/PostIndicateurs'
import PostFilmsSimilaires from '../components/Post/PostFilmsSimilaires'
import PostTriggersWarning from '../components/Post/PostTriggersWarning'
import PostPourCreuserLaQuestion from '../components/Post/PostPourCreuserLaQuestion'
import PostVoirLeFilm from '../components/Post/PostVoirLeFilm'
import PostLinks from '../components/Post/PostLinks'
import PostDetails from '../components/Post/PostDetails'
import PostStickyInfos from '../components/Post/PostStickyInfos'
import PostBemol from '../components/Post/PostBemol'
import SEO from '../components/SEO'

const PostTemplate = ({ data, pageContext }) => {
  const {
    title,
    slug,
    categories,
    dateDeSortie,
    nombreDeSaisons,
    enCoursOuTerminee,
    duree,
    realisateurices,
    coupsDeCoeur,
    metaDescription,
    heroImage,
    voirLeFilm,
    body,
    tags,
    representation,
    personnesConcernees,
    feelGood,
    militant,
    deconstruction,
    indicateurs,
    filmsSimilaires,
    triggersWarning,
    descriptionTriggerWarning,
    pourCreuserLaQuestion,
    bemol,
  } = data.contentfulPost

  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext

  let ogImage
  try {
    ogImage = heroImage.ogimg.src
  } catch (error) {
    ogImage = null
  }

  return (
    <Layout>
      <SEO
        title={title}
        description={
          metaDescription
            ? metaDescription.internal.content
            : body.childMarkdownRemark.excerpt
        }
        image={ogImage}
      />
      <Hero title={title} image={heroImage} height={'50vh'} />
      <Container>
        <PostStickyInfos
          title={title}
          categories={categories}
          tags={tags}
          dateDeSortie={dateDeSortie}
          realisateurices={realisateurices}
          coupsDeCoeur={coupsDeCoeur}
          voirLeFilm={voirLeFilm}
          basePath={basePath}
        />
        {tags && <TagList tags={tags} basePath={basePath} />}
        <PostDetails
          coupsDeCoeur={coupsDeCoeur}
          categories={categories}
          dateDeSortie={dateDeSortie}
          nombreDeSaisons={nombreDeSaisons}
          enCoursOuTerminee={enCoursOuTerminee}
          duree={duree}
          realisateurices={realisateurices}
          basePath={basePath}
        />
        <ContainerBody>
          {triggersWarning && (
            <PostTriggersWarning
              slug={slug}
              triggersWarning={triggersWarning}
              descriptionTriggerWarning={descriptionTriggerWarning}
              basePath={basePath}
            />
          )}
          {voirLeFilm && (
            <PostVoirLeFilm voirLeFilm={voirLeFilm} categories={categories} />
          )}
          <PageBody body={body} />
          <PostIndicateurs
            representation={representation}
            personnesConcernees={personnesConcernees}
            feelGood={feelGood}
            militant={militant}
            deconstruction={deconstruction}
            descriptionIndicateurs={indicateurs}
          />
          {bemol && <PostBemol bemol={bemol} />}
          {filmsSimilaires && (
            <PostFilmsSimilaires
              filmsSimilaires={filmsSimilaires}
              basePath={basePath}
            />
          )}
          {pourCreuserLaQuestion && (
            <PostPourCreuserLaQuestion
              pourCreuserLaQuestion={pourCreuserLaQuestion}
            />
          )}
        </ContainerBody>
      </Container>
      <PostLinks previous={previous} next={next} basePath={basePath} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPost(slug: { eq: $slug }) {
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        ogimg: resize(width: 1800) {
          src
        }
      }
      title
      slug
      tags {
        title
        id
        slug
      }
      categories {
        nom
        slug
      }
      dateDeSortie
      nombreDeSaisons
      enCoursOuTerminee
      duree
      realisateurices {
        nom
        id
        slug
      }
      coupsDeCoeur
      triggersWarning {
        trigger
        id
        slug
      }
      descriptionTriggerWarning {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      voirLeFilm
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      representation
      personnesConcernees
      feelGood
      militant
      deconstruction
      indicateurs {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      bemol {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      filmsSimilaires {
        heroImage {
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        title
        categories {
          nom
        }
        realisateurices {
          nom
          id
        }
        dateDeSortie
        tags {
          title
        }
        id
        slug
      }
      pourCreuserLaQuestion {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      metaDescription {
        internal {
          content
        }
      }
    }
  }
`

export default PostTemplate
