import React from 'react'
import styled from '@emotion/styled'

const PourCreuserLaQuestion = styled.div`
  margin-bottom: 50px;
  p {
    text-decoration: none;
    font-style: normal;
  }
  h2 {
    margin: 0 0 10px 0;
    margin-top: 100px;
  }
  ul li {
    text-decoration: underline;
    font-style: italic;
  }
`

const PostPourCreuserLaQuestion = props => {
  return (
    <PourCreuserLaQuestion>
      <div
        dangerouslySetInnerHTML={{
          __html: props.pourCreuserLaQuestion.childMarkdownRemark.html,
        }}
      />
    </PourCreuserLaQuestion>
  )
}

export default PostPourCreuserLaQuestion
