import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import 'cooltipz-css'
import { Link } from 'gatsby'
import { FaFilm } from 'react-icons/fa'
import NomDeLaCategorie from '../../hooks/use-nom-categorie'

const BarInfo = styled.div`
  --cooltipz-bg-color: transparent;
  --cooltipz-text-color: ${props => props.theme.colors.background};
  --cooltipz-cursor: default;
  --cooltipz-arrow-size: 2px;
  [aria-label][data-cooltipz-dir][data-cooltipz-dir='bottom']:before {
    border-bottom-color: transparent;
  }
  display: none;
  h1 {
    cursor: pointer;
    text-align: center;
    color: white;
    font-size: 1.9rem;
    margin-bottom: 1rem;
    font-family: ${props => props.theme.fonts.titles};
  }
  .bar-info-infos {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  h2 {
    color: white;
    font-size: 0.85rem;
    margin-top: 10px;
    svg {
      vertical-align: middle;
      margin-bottom: 2px;
      margin-right: 5px;
    }
  }
  a {
    text-decoration: none;
    color: white;
  }

  @media screen and (max-width: 400px) {
    .bar-info-button {
      display: none;
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    min-height: 2rem;
    height: 5rem;
    max-height: 6rem;
    transform: translate3d(0, -1000%, 0);
    flex-wrap: wrap;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    z-index: 999;
    background-color: ${props => props.theme.colors.primary};
    transition: transform 0.4s;
    .bar-info-infos {
      flex-direction: row;
    }
    h1 {
      margin: auto 1.9rem auto 0;
    }
    h2 {
      margin: 0.5rem 1.4rem 0 0;
    }
    h4 {
      margin-bottom: 0;
    }
  }
`

const StyledButton = styled.div`
  button {
    &:hover {
      background-color: #2e629e;
    }
  }
  a {
    text-decoration: none;
    margin: 0 20px;
    transition: all 10ms;
  }
`
const Coeur = styled.h2`
  display: inline-block;
  text-shadow: 2px 0 2px #000000b3;
  transform: scale(1.3);
  opacity: 0.8;
  text-align: center;
`

const PostStickyInfos = props => {
  useEffect(() => {
    const body = document.body
    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset
      if (currentScroll <= screen.height) {
        body.classList.remove('scroll-up')
      } else if (currentScroll > screen.height) {
        // show infos
        body.classList.add('scroll-up')
      }
    })
  })

  const backToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <BarInfo className="bar-info">
      <h1 onClick={backToTop}>{props.title}</h1>
      <div className="bar-info-infos">
        <h2>
          <FaFilm />
          &nbsp;
          <Link to={`${props.basePath}/categorie/${props.categories.slug}/`}>
            {props.categories.nom}
          </Link>
          &nbsp;/&nbsp;
          {props.tags.slice(0, 1)[0].title}
        </h2>
        <h2>
          <span>🎬&nbsp;</span>&nbsp;
          {`Réalisé par ${props.realisateurices
            .map(realisateurice => realisateurice.nom)
            .join(', ')}`}
          &nbsp; ({props.dateDeSortie})
        </h2>

        {props.coupsDeCoeur && (
          <span aria-label="Coups de coeur !" data-cooltipz-dir="bottom">
            <Coeur>❤️</Coeur>
          </span>
        )}
      </div>
      <div className="bar-info-button">
        {props.voirLeFilm && (
          <StyledButton>
            <button
              className="my-button"
              type="primary"
              size="large"
              aria-label={`Voir ${NomDeLaCategorie(props)}`}
            >
              <a href={props.voirLeFilm} target="_blank" rel="noreferrer">
                Voir {NomDeLaCategorie(props)}
              </a>
            </button>
          </StyledButton>
        )}
      </div>
    </BarInfo>
  )
}

export default PostStickyInfos
