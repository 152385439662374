import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { FaFilm } from 'react-icons/fa'

const TousLesFilms = styled.div`
  border: 1px solid grey;
  background-color: #f3f3f3;
  margin-bottom: 20px;
  border-radius: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
`
const Wrapp = styled.div`
  display: flex;
  overflow: hidden;
  margin: 10px auto;
  height: 200px;
  width: 140px;
  border-radius: 9px;
  z-index: 1;
  opacity: 0.9;
  transition: all 200ms;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 10px;
  }

  & > div {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    bottom: 0;
    height: 35%;
    width: 100%;
    z-index: 99;
    color: white;
    opacity: 0;
    background: ${props => props.theme.colors.primary};
    transition: all 200ms;
  }
  &:hover > div,
  &:focus > div {
    display: flex;
    opacity: 1;
  }
  &:hover,
  &:focus {
    opacity: 1;
    transform: scale(1.4);
    z-index: 99;
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      transform: scale(1.8);
    }
  }
  &:hover h4 ~ div,
  &:focus h4 ~ div {
    height: 150px;
  }

  a {
    position: relative;
    width: 150px;
  }
  h4 {
    position: absolute;
    text-align: center;
    z-index: 3;
    font-family: ${props => props.theme.fonts.titles};
    color: white;
    font-size: clamp(1rem, 20px, 1.2rem);
    margin-top: 20px;
    min-width: 140px;
    word-break: break-word;
    text-shadow: 0px 0px 4px black, 0px 0px 2px black;
  }
`
const StImg = styled(Img)`
  height: 200px;
  transition: all ease 200ms;
`
const Infos = styled.div`
  font-size: 0.55rem;
  font-weight: 100;
  max-height: 30%;
  p,
  em {
    margin-bottom: 0;
    margin-left: 4px;
    svg {
      vertical-align: middle;
      margin-bottom: 1px;
    }
  }
  p:nth-of-type(1) {
    font-size: 0.5rem;
  }
  p:nth-of-type(2) {
    font-size: 0.45rem;
    color: ${props => props.theme.colors.secondary};
  }
  em {
    display: block;
    margin: 3px 4px;
    font-size: 0.4rem;
    line-height: 0.6rem;
    color: ${props => props.theme.colors.secondary};
  }
`

const PostIndicateurs = props => {
  return (
    <Fragment>
      <h1 style={{ marginTop: '2rem' }}>Quelques films similaires :</h1>
      <TousLesFilms>
        {props.filmsSimilaires.map(film => (
          <Wrapp key={film.id}>
            <Infos>
              <p>
                <FaFilm />
                &nbsp;
                {film.categories.nom}&nbsp;/&nbsp;
                {film.tags.slice(0, 1)[0].title}
              </p>
              <p>
                🎬&nbsp;
                {`${film.realisateurices
                  .map(realisateurice => realisateurice.nom)
                  .join(', ')}`}
                &nbsp; ({film.dateDeSortie})
              </p>
              <em>{film.tags.map(tag => tag.title).join(', ')}.</em>
            </Infos>
            <Link to={`${props.basePath}/${film.slug}/`}>
              <h4>{film.title}</h4>
              <StImg
                fluid={film.heroImage.fluid}
                backgroundColor={'#000'}
                alt={`Un screenshot de ${props.title}`}
              />
            </Link>
          </Wrapp>
        ))}
      </TousLesFilms>
    </Fragment>
  )
}
export default PostIndicateurs
